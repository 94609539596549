import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService, IPolicy, } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { USER_ID, FORGET_USER_ID, USER_LOGIN, TOKEN, ADMIN, USER_TYPE, DOCS_URL, ADMIN_USER_ID } from '@prcins/constants';
import { FormValidatorService, RegistrationService } from '@prcins/utils';
import { CookieService } from 'ngx-cookie-service';
import { TealiumTrackerService } from 'libs/utils/src/lib/services/tealium/tealium-tracker.service';
import { ClaimsBasicService } from "libs/claims/src/lib/claims-basic.service";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '@prcins/environments';
import { Observable } from 'rxjs';


declare global {
  interface Window {
    grecaptcha: any;
  }
}

@Component({
  selector: 'prcins-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input() invitationDetails;
  isAdmin: boolean = false;
  loginForm: FormGroup = this.fb.group({
    userName: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')
      ]
    ],
    password: ['', [Validators.required]],
    rememberMe: ['']
  });
  showSpinner: boolean;
  maxAttemptsError: boolean;
  warnLockouts: any;
  attemptsLeft: any;
  error: string;
  accountLockError: boolean;
  showMobileIdCardPopup: boolean;
  displayMessage: string;
  showDownload: boolean;
  maskPassword = false;
  forgotEmailFlow = false;
  mobileApp = (self != top) ? true : false;
  additionalInvitationMessage: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private formValidator: FormValidatorService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private tealiumTracker: TealiumTrackerService,
    private claimsBasicService: ClaimsBasicService,
    private registrationProvider: RegistrationService,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.registrationProvider.invalidateEndorsementSession();
    if (history.state.email) {
      this.loginForm.get('userName').setValue(history.state.email);
      this.forgotEmailFlow = true;
    }

    if (history.state.edocRedirectStatus === "SUCCESS") {
      this.loginService.statusNotification$.next({ type: 'INFO', message: 'Congratulations! You have successfully enrolled in eDocuments!' })
    }


    let userId = sessionStorage.getItem(USER_ID);
    userId = userId ? userId : this.cookieService.get('es_Email');
    this.isAdmin = this.router.url.indexOf('admin') !== -1;
    const userType = this.isAdmin ? "admin" : "user";
    sessionStorage.setItem(USER_TYPE, userType);
    if (userId) {
      this.loginForm.get('rememberMe').setValue(this.cookieService.get('es_Email_RememberMe'));
      if (this.cookieService.get('es_Email_RememberMe')) {
        this.loginForm.get('userName').setValue(this.cookieService.get('es_Email'));
      }
    }
    if (sessionStorage.getItem(FORGET_USER_ID)) {
      this.loginForm.get('userName').setValue(sessionStorage.getItem(FORGET_USER_ID));
      sessionStorage.removeItem(FORGET_USER_ID);
    }

    this.additionalInvitationMessage = this.invitationDetails && this.invitationDetails.additionalMessage ? this.invitationDetails.additionalMessage : "";

    if (this.invitationDetails && this.invitationDetails.prePopulateEmail && this.invitationDetails.emailAddress) {
      this.loginForm.controls['userName'].setValue(this.invitationDetails.emailAddress);
    }

    let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : this.cookieService.get('token')

    if (userId && token) {
      this.autoLogin(userId, token);
    }
  }

  autoLogin(userId: string, token: string) {
    sessionStorage.setItem(USER_LOGIN, 'Y');
    sessionStorage.setItem(USER_ID, userId);
    sessionStorage.setItem(TOKEN, token);
    this.loginService.userLoggedIn$.next(true);
    this.showSpinner = false;
    const routePath = this.isAdmin
      ? ['eservice/admin/activity']
      : ['eservice/home'];
    //this.loadPolicies(routePath, window.location.search.substr(1) == "mobileIdCardsView=yes");
    if (window.location.search.substr(1) == "mobileIdCardsView=yes") {
      this.loadPolicies();
    } else {
      this.router.navigate(routePath);
    }
  }

  onLogin() {
    if (this.loginForm.valid) {
      this.showSpinner = true;
      this.error = null;
      this.accountLockError = false;
      this.maxAttemptsError = false;


      const { userName: userId, password } = this.loginForm.value;

      // const payload = {
      //   "policyNumber": "",
      //   "agreementType": "GENERALTC",
      //   "agreementType2": "ELECTRONICCONSENT",
      //   "source": "ESERVICE",
      //   "email": userId
      // }
      // this.claimsBasicService.eDiscloserAudit(payload).subscribe();

      const request = {
        userId: userId.toLowerCase(),
        password,
        recordToDB: 'Y',
        company: 'PR',
        userType: this.isAdmin ? 'idg_employee_group' : 'idg_user_group'
      };

      if (!this.mobileApp) {
        if (window.grecaptcha) {
          window.grecaptcha.enterprise.ready(() => {
            var context = this;
            var loginRequest = request;
            var env = environment;
            window.grecaptcha.enterprise.execute(env.SITE_KEY, { action: 'LOGIN' })
              .then(function (token) {
                context.loginCall(loginRequest, token);
              }, error => {
                console.log(error);
                this.loginCall(request, '');
              })
          }, error => {
            console.log(error);
            this.loginCall(request, '');
          })
        } else {
          this.showSpinner = false;
          this.error =
            'Oops! We seem to be having a problem. Please try again later or contact us for help.';
        }
      } else {
        this.loginCall(request, '');
      }

    } else {
      this.formValidator.validateAllFormFields(this.loginForm);
    }
  }

  loginCall(request, token) {
    this.loginService.login(request, token).subscribe(
      result => {

        if (result.messageCode === 'LOGIN_SUCCESSFUL') {

          this.refreshCookie(this.loginForm.get('rememberMe').value, request.userId, result.token);

          result.userId = request.userId;
          sessionStorage.setItem(USER_LOGIN, 'Y');
          sessionStorage.setItem(USER_ID, request.userId);
          sessionStorage.setItem(TOKEN, result.token);
          sessionStorage.setItem('randomNumber', Math.random().toString());
          if (this.isAdmin) {
            sessionStorage.setItem(ADMIN, 'Y');
            sessionStorage.setItem(ADMIN_USER_ID, request.userId);
          }

          this.showSpinner = false;
          const routePath = this.isAdmin
            ? ['eservice/admin/activity']
            : ['eservice/home'];
          //this.loadPolicies(routePath, window.location.search.substr(1) == "mobileIdCardsView=yes");
          if (window.location.search.substr(1) == "mobileIdCardsView=yes") {
            this.loadPolicies();
          } else {
            if(this.route.snapshot.queryParams && this.route.snapshot.queryParams.redirectType && this.route.snapshot.queryParams.redirectType === 'claimdetails' && this.route.snapshot.queryParams.claimid) {
              sessionStorage.setItem("currentPolicy",this.route.snapshot.queryParams.pn);
              let routePath = ['eservice/home/claims/'+this.route.snapshot.queryParams.claimid];
              this.router.navigate(routePath,{ queryParams: { restoreSession: 'true' } } );
            } else {
              this.router.navigate(routePath);
            }
           
          }

        } else {
          this.handleloginErrors(result);
        }
      },
      error => {
        if ((error instanceof HttpErrorResponse) && error.error ) {
          this.handleloginErrors(error.error);
        }else{
          this.error = 'Login Failed';
          this.showSpinner = false;
          console.log('Error calling login Service....', error);
        }
        
      }
    );
  }

  handleloginErrors(result){
    if (result.messageCode === 'PASSWORD_MISMATCH') {
      this.showSpinner = false;
      this.error =
        'Oops! Email or password is incorrect. Please try that again.';
    } else if (result.messageCode === 'ACCOUNT_LOCKOUT_WARN') {
      this.showSpinner = false;
      this.maxAttemptsError = true;
      this.warnLockouts = result.warnLockout;
      this.attemptsLeft = result.attemptsLeft;
    } else if (result.messageCode === 'ACCOUNT_NOT_REGISTERED') {
      this.showSpinner = false;
      this.error =
        'Oops! Email or password is incorrect. Please try that again.';
    } else if (result.messageCode === 'NEED_NEW_PASSWORD') {
      this.showSpinner = false;
      this.error =
        'Oops! Email or password is incorrect. Please try that again.';
    } else if (result.messageCode === 'UNAVAILABLE_FOR_15MIN') {
      this.maxAttemptsError = false;
      this.showSpinner = false;
      this.accountLockError = true;
    } else if (result.messageCode === 'SERVICE_ERROR') {
      this.showSpinner = false;
      this.error =
        'Oops! We seem to be having a problem. Please try again later or contact us for help.';
    } else if (result.messageCode === 'FAB_DOWN_ERROR') {
      this.showSpinner = false;
      this.error =
        'Oops! We seem to be having a problem. Please try again later or contact us for help.';
    } else if (result.messageCode === 'POLSTAR_DOWN_ERROR') {
      this.showSpinner = false;
      this.error =
        'Oops! We seem to be having a problem. Please try again later or contact us for help.';
    } else {
      this.showSpinner = false;
      this.error =
        'Oops! Email or password is incorrect. Please try that again.';
    }
  }

  refreshCookie(flag, userId, token) {
    const domain = location.hostname;
    const isSecure = location.host.indexOf('plymouthrock.com') > -1 ? true : false;
    const sameSite = "None";
    this.cookieService.set(TOKEN, token, null, '/');
    this.cookieService.set('es_Email', userId, 1000, '/');

    //this.cookieService.set(TOKEN, token, null, '/', domain, isSecure, sameSite);
    //this.cookieService.set('es_Email', userId, 1000, '/', domain, isSecure, sameSite);

    if (flag) {
      this.cookieService.set('es_Email_RememberMe', 'true', 1000, '/');
      //this.cookieService.set('es_Email_RememberMe', 'true', 1000, '/', domain, isSecure, sameSite);
    } else {
      this.cookieService.set('es_Email_RememberMe', '', 1000, '/');
      //this.cookieService.set('es_Email_RememberMe', '', 1000, '/', domain, isSecure, sameSite);
      //this.cookieService.set('es_Email', '', 1000, '/', domain, isSecure, sameSite);
      this.cookieService.set('es_Email', '', 1000, '/');
    }
  }

  loadPolicies() {
    this.showSpinner = true;
    this.loginService
      .getMobileRegisteredPolicies(sessionStorage.getItem(USER_ID))
      .subscribe(
        val => {
          this.mobileIdCardDownload(val);
        },
        () => {
          this.showSpinner = false;
          // error handling
        }
      );
  }

  mobileIdCardDownload(data) {

    let checkAnyAutoPolicy = false;
    let validVehicleType = false;
    let noNyPolicy = true;

    data.forEach(policy => {
      console.log(policy);

      if ((policy.policyInfo.lob == "PA" || policy.policyInfo.lob == "MC") && (policy.policyInfo.statusCode.indexOf('Active') !== -1 || policy.policyInfo.statusCode.indexOf('Cancellation') !== -1)) {
        checkAnyAutoPolicy = true;
      }

      if (policy.policyInfo.state == "NY") {
        if ((policy.policyInfo.companyCode == "ALN_PIC" || policy.policyInfo.companyCode == "ALN_PRAPC" || policy.policyInfo.companyCode == "ALN_PRACNY")) {
          noNyPolicy = true;
        } else {
          noNyPolicy = false;
        }
      }
      if (checkAnyAutoPolicy && policy.inquiry.vehicles) {
        policy.inquiry.vehicles.forEach(veh => {
          console.log(veh.entityCode)
          if (veh.entityCode == "PPA" || veh.entityCode == "MH" || veh.entityCode == "AQ" || veh.entityCode == "CL" || veh.entityCode == "MC") {
            validVehicleType = true;
          }
        });
      }

      this.showDownloadPopUp(checkAnyAutoPolicy, validVehicleType, noNyPolicy);
      this.showSpinner = false;
    });


  }

  showDownloadPopUp(checkAnyAutoPolicy, validVehicleType, noNyPolicy) {
    if (checkAnyAutoPolicy && validVehicleType && noNyPolicy) {

      this.showMobileIdCardPopup = true;
      this.showDownload = true;


    } else if (!noNyPolicy) {

      this.showMobileIdCardPopup = true;
      this.displayMessage = "For policies underwritten by 21st Century National Insurance Company, 21st Century Advantage Insurance Company, and 21st Century North America Insurance Company, mobile ID cards are not currently available.  Please contact our Customer Solutions department with any questions you may have.";

    } else {

      this.showMobileIdCardPopup = true;
      this.displayMessage = "Mobile ID Cards are only available on active Plymouth Rock personal auto and motorcycle policies registered for web self-service. You can register your Plymouth Rock personal auto and/or motorcycle policy by going to Manage My Policy on this app’s Home page.";


    }
  }

  downloadIdCardData() {
    this.showSpinner = true;
    this.showMobileIdCardPopup = false;
    this.tealiumTracker.track("mobile-idcard");

    setTimeout(() => {

      this.loginService
        .requestMobileIdCard(sessionStorage.getItem(USER_ID))
        .subscribe(response => {
          if (response.fileId) {
            var pdfOpenUrl = `${DOCS_URL.downloadMobile}/${sessionStorage.getItem(USER_ID)}/${response.fileId}`;
            //pdfOpenUrl = [pdfOpenUrl, 'token=' + response.token].join('?');
            const ua = navigator.userAgent.toLowerCase();
            window.open(pdfOpenUrl, "_self");
          }
        });
    }, 2000);

  }

  goToHome() {
    this.router.navigate(['eservice/home']);
  }

  onRememberMeChanged(item) {
    this.loginForm.get('rememberMe').setValue(item.value);
  }

  goToResetPassword() {
    this.error = null;
    if (this.loginForm.get('userName').errors == null) {
      sessionStorage.setItem('userId', this.loginForm.get('userName').value);
      let data = {
        origin: 'LOGIN',
        userName: this.loginForm.get('userName').value
      }
      this.registrationProvider.forgotPassword(data).subscribe((resp: any) => {
        if (resp.messageCode === undefined || resp.messageCode === '') {
          this.showSpinner = false;
          sessionStorage.setItem('passwordActionType', 'Reset');
          sessionStorage.setItem('verificationId', resp.id);
          this.registrationProvider.verifyUserRegisteredOrNot$.next(resp);
          this.router.navigate(['eservice/verification']);
        } else {
          this.error = 'Please provide your valid email to reset the password.';
        }
      }, (error: HttpErrorResponse) => {
        this.showSpinner = false;
      });
    } else {
      this.error = 'Please provide your valid email to reset the password.';
    }
  }

  goToRegister() {
    this.registrationProvider.userRegistrationForm$.next(null);
    sessionStorage.setItem('passwordActionType', 'Create');
    this.router.navigate(['eservice/register']);
  }

  goToForgotEmail() {
    this.registrationProvider.userRegistrationForm$.next(null);
    sessionStorage.setItem('origin', 'forgotEmailFlow');
    this.router.navigate(['eservice/forgot-email']);
  }

  showEDocSuccessMessage() {
    return ;
}
}
