import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { DangerComponent } from './danger/danger.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { CardComponent } from './card/card.component';
import { PrimaryComponent } from './primary/primary.component';
import { CardFormComponent } from './card-form/card-form.component';
import { BankFormComponent } from './bank-form/bank-form.component';
import { RoundToggleSwitchComponent } from './round-toggle-switch/round-toggle-switch.component';
import { SquareCheckboxComponent } from './square-checkbox/square-checkbox.component';
import { TogglerComponent } from './toggler/toggler.component';
import { LobLabelPipe } from './pipes/lob-label.pipe';
import { NotificationComponent } from './notification/notification.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { TelephonePipe } from './pipes/telephone.pipe';
import { FooterComponent } from './footer/footer.component';
import { EsPopupComponent } from './es-popup/es-popup.component';
import { ErrorpopupComponent } from './errorpopup/errorpopup.component'
import { PageTrackerDirective } from './tealium/page-tracker.directive';
import { ClickTrackerDirective } from './tealium/click-tracker.directive';
import { WelcomeBannerComponent } from './welcome-banner/welcome-banner.component';
import { DesktopBannerComponent } from './welcome-banner/desktop-banner/desktop-banner.component';
import { MobileBannerComponent } from './welcome-banner/mobile-banner/mobile-banner.component';
import { UcFirstPipe } from './pipes/uc-first.pipe';
import { PhoneEmailDirective } from './phone-email/phone-email.directive';
import { RoundRadioComponent } from './round-radio/round-radio.component';
import { UserFormComponent } from './user-form/user-form.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { PolicyTypeResolverComponent } from './policy-type-resolver/policy-type-resolver.component';
import { AccordionComponent } from './accordion/accordion.component';
import { ReviewAccordionComponent } from './review-accordion/review-accordion.component';
import { ErrorLabelComponent } from './error-label/error-label.component';
import { OnlyNumberDirective } from './only-number/only-number.directive';
import { LobComponent } from './lob/lob.component';
import { MaskPipe } from './pipes/mask.pipe';
import { TrimInputDirective } from './directives/trim-input.directive';
import { MrbNotificationComponent } from './mrb-notification/mrb-notification.component';
import { MrbRestrictedDirective } from './directives/mrb-restricted.directive';
import { OnlyNumberWithDashDirective } from './only-number-with-dash/only-number-with-dash.directive';
import { IdCardsNotificationComponent } from './id-cards-notification/id-cards-notification.component';
import { EdocsPopupComponent } from './edocs-popup/edocs-popup.component';
import { NoCopyPasteDirective } from './no-copy-paste/no-copy-paste.directive';
import { NoRightClickDirective } from './no-right-click/no-right-click.directive';
import { PhonemaskDirective } from './phone-mask/phone-mask.directive';
import { PhonevalidationDirective } from './phone-validation/phone-validation.directive';
import { IsNotBlankDirective } from './directives/is-not-blank.directive';
import { InvalidControlScrollDirective } from './directives/scroll-to-invalid.directive';
import { InputtrimDirective } from './inputtrim.directive';
import { SecureUsDateFormatMaskDirective } from './directives/secure-us-date-format-mask.directive';
import { UsDateFormatMaskDirective } from './directives/us-date-format-mask.directive';
import { HelpTextComponent } from './help-text/help-text.component';
import { RegisterUserFormComponent } from './register-user-form/register-user-form.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { ManageAlertsAccordionComponent } from './manage-alerts-accordion/manage-alerts-accordion.component';
import { SessionInitializer } from './decorators/session.decorator';
import { StaticInjectorService } from './services/static-injector.service';
import { MessageBannerComponent } from './welcome-banner/message-banner/message-banner.component';
import { SessionRedirectErrorComponent } from './session-redirect-error/session-redirect-error.component';
import { CrossSellPromotionWidgetComponent } from './cross-sell-promotion-widget/cross-sell-promotion-widget.component';
import { EdocPromotionBannerComponent } from './edoc-promotion-banner/edoc-promotion-banner.component';

@NgModule({
  providers:[StaticInjectorService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    DangerComponent,
    SpinnerComponent,
    TermsConditionsComponent,
    CardComponent,
    PrimaryComponent,
    CardFormComponent,
    BankFormComponent,
    RoundToggleSwitchComponent,
    SquareCheckboxComponent,
    TogglerComponent,
    LobLabelPipe,
    NotificationComponent,
    SkeletonComponent,
    TelephonePipe,
    FooterComponent,
    EsPopupComponent,
    ErrorpopupComponent,
    PageTrackerDirective,
    ClickTrackerDirective,
    WelcomeBannerComponent,
    DesktopBannerComponent,
    MobileBannerComponent,
    UcFirstPipe,
    PhoneEmailDirective,
    RoundRadioComponent,
    UserFormComponent,
    TooltipComponent,
    PolicyTypeResolverComponent,
    AccordionComponent,
    ReviewAccordionComponent,
    ErrorLabelComponent,
    OnlyNumberDirective,
    LobComponent,
    MaskPipe,
    TrimInputDirective,
    MrbNotificationComponent,
    MrbRestrictedDirective,
    OnlyNumberWithDashDirective,
    IdCardsNotificationComponent,
    EdocsPopupComponent,
    NoCopyPasteDirective,
    NoRightClickDirective,
    PhonemaskDirective,
    PhonevalidationDirective,
    IsNotBlankDirective,
    InvalidControlScrollDirective,
    InputtrimDirective,
    SecureUsDateFormatMaskDirective,
    UsDateFormatMaskDirective,
    HelpTextComponent,
    ManageAlertsAccordionComponent,
    RegisterUserFormComponent,
    PasswordFormComponent,
    MessageBannerComponent,
    SessionRedirectErrorComponent,
    CrossSellPromotionWidgetComponent,
    EdocPromotionBannerComponent
  ],
  exports: [
    DangerComponent,
    SpinnerComponent,
    TermsConditionsComponent,
    CardComponent,
    PrimaryComponent,
    CardFormComponent,
    BankFormComponent,
    RoundToggleSwitchComponent,
    SquareCheckboxComponent,
    LobLabelPipe,
    NotificationComponent,
    SkeletonComponent,
    TelephonePipe,
    FooterComponent,
    EsPopupComponent,
    PageTrackerDirective,
    ClickTrackerDirective,
    WelcomeBannerComponent,
    UcFirstPipe,
    PhoneEmailDirective,
    RoundRadioComponent,
    UserFormComponent,
    TooltipComponent,
    PolicyTypeResolverComponent,
    AccordionComponent,
    ReviewAccordionComponent,
    ErrorLabelComponent,
    ErrorpopupComponent,
    OnlyNumberDirective,
    OnlyNumberWithDashDirective,
    LobComponent,
    MaskPipe,
    TrimInputDirective,
    MrbNotificationComponent,
    MrbRestrictedDirective,
    IdCardsNotificationComponent,
    EdocsPopupComponent,
    NoCopyPasteDirective,
    NoRightClickDirective,
    PhonemaskDirective,
    PhonevalidationDirective,
    IsNotBlankDirective,
    InvalidControlScrollDirective,
    InputtrimDirective,
    SecureUsDateFormatMaskDirective,
    UsDateFormatMaskDirective,
    HelpTextComponent,
    ManageAlertsAccordionComponent,
    RegisterUserFormComponent,
    PasswordFormComponent,
    MessageBannerComponent,
    CrossSellPromotionWidgetComponent,
    EdocPromotionBannerComponent
  ]
})
export class UtilsModule {
  constructor(_: StaticInjectorService) {}
 }
